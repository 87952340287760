import React, { Component } from 'react';
import { withTranslation } from 'next-i18next';
// Page Plugins
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { withRouter } from 'next/router';
class Index extends Component {
    constructor(props) {
        super(props);
    }

    sign_in = () => {
        this.props.router.push("/nft/login");
    }

    render() {
        const { t } = this.props;
        return (
            <>
                <Modal backdrop={"static"}
                    show={this.props.signInWithWallet}
                    onHide={this.props.closeSignInWithWallet}
                    size="md"
                    centered
                    className='placeBid'
                    aria-labelledby="contained-modal-title-vcenter">
                    <div className="popup popup_bid">
                        <div className="popup__title h4">
                            <Button type="button" className="btnClose" onClick={this.props.closeSignInWithWallet}>
                                <svg xmlns='http://www.w3.org/2000/svg' width='14' height='15' fill='none' viewBox='0 0 14 15'><path fillRule='evenodd' d='M.293 1.289a1 1 0 0 1 1.414 0L7 6.582l5.293-5.293a1 1 0 0 1 1.414 1.414L8.414 7.996l5.293 5.293a1 1 0 0 1-1.414 1.414L7 9.41l-5.293 5.293a1 1 0 0 1-1.414 0 1 1 0 0 1 0-1.414l5.293-5.293L.293 2.703a1 1 0 0 1 0-1.414z' fill='#23262F' /></svg>
                            </Button>
                        </div>
                        <div className="text-center steps__info">
                            <svg width="60" height="60" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="20" cy="20" r="20" fill="#EFF0FB" />
                                <path d="M10 19.7138L19.9997 4L29.9994 19.7138L19.9997 25.4279L10 19.7138Z" fill="#7880E7" />
                                <path d="M20 4L29.9997 19.7138L20 25.4279V4Z" fill="#5C64C7" />
                                <path d="M10 21.8564L19.9997 27.5706L29.9994 21.8564L19.9997 35.4275L10 21.8564Z" fill="#7880E7" />
                                <path d="M19.9997 27.5712L29.9994 21.8571L19.9997 35.4281V27.5712ZM10 19.7143L19.9997 15.4287L29.9994 19.7143L19.9997 25.4284L10 19.7143Z" fill="#5C64C7" />
                                <path d="M20 15.4287L29.9997 19.7143L20 25.4284V15.4287Z" fill="#2A3192" />
                            </svg>
                            <div className="popup__info mt-4">{t('common:signwithWallet')}</div>
                        </div>
                    </div>
                    <Button type="button" className="btn" onClick={this.sign_in}>{t('common:sign_in')}</Button>
                </Modal>
            </>
        )
    }
}

export default withTranslation()(withRouter(Index));